import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import EventsList from './components/EventsList';
import ProtectedRoute from './components/ProtectedRoute';
import EventForm from './components/EventForm';
import RecentStudents from './components/RecentStudents';
import FolderAccess from './Folder/FolderAccess';
import FolderIscom from './Folder/Folder_Iscom';
import FolderPigier from './Folder/Folder_Pigier';
import FolderConnect from './Folder/Folder_Connect';
import "./style/private/CalendarInput.css"
import "./style/private/CheckboxList.css"
import "./style/private/ErrorReportPopup.css"
import "./style/private/FilterOptions.css"
import "./style/private/Header.css"
import "./style/private/Historic.css"
import "./style/private/Loader.css"
import "./style/private/Logo.css"
import "./style/private/ScoringComponent.css"
import "./style/private/SMSSection.css"
import "./style/private/TextFormatter.css"
import "./style/private/ToggleSlider.css"
import "./style/private/Home.css"
import "./style/private/Panel.css"
import "./style/private/Update.css"


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/candidats/access/" element={<FolderAccess></FolderAccess>}></Route>
          <Route path="/candidats/iscom/folder/:folder_id" element={<FolderIscom></FolderIscom>}></Route>
          <Route path="/candidats/pigier/folder/:folder_id" element={<FolderPigier></FolderPigier>}></Route>
          <Route path="/connect/" element={<FolderConnect></FolderConnect>}></Route>
          <Route 
            path="/events" 
            element={
              <ProtectedRoute>
                <EventsList />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/event-form/:eventId" 
            element={
              <ProtectedRoute>
                <EventForm />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/recent-students" 
            element={
              <ProtectedRoute>
                <RecentStudents />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
