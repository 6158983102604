import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/EventsList.css';

import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"

function EventsList() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(localStorage.getItem('selectedEvent') || null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProtectedData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios.get('https://csmapi.up.csmrouen.info/events', {
          headers: {
            Authorization: `Bearer ${token}` // Send the token in the Authorization header
          }
        });
        setIsAuthorized(true); // Set authorized state to true on success
      } catch (error) {
        console.error('Error fetching protected data:', error);
        setIsAuthorized(false); // Set authorized state to false on error
        navigate('/login'); // Redirect to login page if not authorized
      }
    };
    fetchProtectedData();

    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://csmapi.up.csmrouen.info/get-events');
        console.log(response.data);
        // Filter events where options isn't null
        const filteredEvents = response.data.filter(event => event.options !== "");
        setEvents(filteredEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, [navigate]);

  const handleEventChange = (e) => {
    const eventId = e.target.value;
    setSelectedEvent(eventId);
    localStorage.setItem('selectedEvent', eventId);
  };

  const handleContinue = () => {
    if (selectedEvent) {
      const event = events.find(event => event.id.toString() === selectedEvent);
      navigate(`/event-form/${event.id}`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedEvent');
    navigate('/login');
  };

  return (
    <div className="events-list-container">
      <h2>Sélection d'événement</h2>
      <button className="logout-button" onClick={handleLogout}>Se déconnecter</button>
      {isAuthorized ? (
        <div>
          <button className="recent-students-button" onClick={() => navigate('/recent-students')}>Étudiants récents</button>
        </div>
      ) : null}
      {isAuthorized ? (
        <div className="events-list">
          <form>
            {events.map((event) => (
              <div key={event.id} className="events-list-group">
                <input
                  type="radio"
                  id={`event-${event.id}`}
                  name="selectedEvent"
                  value={event.id}
                  checked={selectedEvent === event.id.toString()}
                  onChange={handleEventChange}
                />
                <label htmlFor={`event-${event.id}`}>{event.name}</label>
              </div>
            ))}
          </form>
          <button className="events-list-validate" onClick={handleContinue}>Continuer</button>
        </div>
      ) : (
        <p className="events-list-error">Accès refusé. Veuillez vous connecter pour accéder à ce contenu.</p>
      )}
    </div>
  );
}

export default EventsList;
